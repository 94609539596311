import { FollowParametricObject } from "@novorender/api";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "app/redux-store-interactions";
import { useExplorerGlobals } from "contexts/explorerGlobals";
import { AsyncState, AsyncStatus } from "types/misc";

import { followPathActions, selectFollowCylindersFrom, selectSelectedIds } from "./followPathSlice";

export function useFollowPathFromIds() {
    const {
        state: { view },
    } = useExplorerGlobals(true);
    const { t } = useTranslation();

    const followFrom = useAppSelector(selectFollowCylindersFrom);
    const toFollow = useAppSelector(selectSelectedIds);
    const dispatch = useAppDispatch();

    const [objects, setObjects] = useState<AsyncState<FollowParametricObject>>({
        status: AsyncStatus.Initial,
    });

    useEffect(() => {
        loadFpObjects();

        async function loadFpObjects() {
            setObjects({ status: AsyncStatus.Loading });
            try {
                const fp = await view.measure?.followPath.followParametricObjects(toFollow, {
                    cylinderMeasure: followFrom,
                });

                if (!fp) {
                    setObjects({
                        status: AsyncStatus.Error,
                        msg: t("objectsCantBeFollowed"),
                    });
                    dispatch(followPathActions.setFollowObject(undefined));
                    return;
                }

                setObjects({ status: AsyncStatus.Success, data: fp });
                dispatch(followPathActions.setFollowObject(fp));
            } catch {
                setObjects({
                    status: AsyncStatus.Error,
                    msg: t("errorOccurred"),
                });
                dispatch(followPathActions.setFollowObject(undefined));
            }
        }
    }, [toFollow, view, dispatch, followFrom, t]);

    return objects;
}
