import { ListItemIcon, ListItemText, MenuItem, MenuList } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "app/redux-store-interactions";
import { featuresConfig } from "config/features";
import { useExplorerGlobals } from "contexts/explorerGlobals";
import { getTopDownParams, selectDefaultTopDownElevation, selectTopDownSnapToAxis } from "features/orthoCam";
import { CameraType, Picker, renderActions, selectPicker } from "features/render";

import { ActiveIcon } from "./activeIcon";

export function ClippingMenu({ onSelect }: { onSelect?: () => void }) {
    const {
        state: { view },
    } = useExplorerGlobals();
    const activePicker = useAppSelector(selectPicker);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const defaultTopDownElevation = useAppSelector(selectDefaultTopDownElevation);
    const snapToNearestAxis = useAppSelector(selectTopDownSnapToAxis) === undefined;

    const togglePicker = (picker: Picker) => {
        dispatch(renderActions.setPicker(picker === activePicker ? Picker.Object : picker));
        onSelect?.();
    };

    return (
        <MenuList>
            <MenuItem onClick={() => togglePicker(Picker.ClippingPlane)}>
                <ListItemIcon>
                    <ActiveIcon
                        Icon={featuresConfig.clippingPlanes.Icon}
                        active={activePicker === Picker.ClippingPlane}
                    />
                </ListItemIcon>
                <ListItemText>{t("clippingPlane")}</ListItemText>
            </MenuItem>
            <MenuItem
                onClick={() => {
                    togglePicker(Picker.CrossSection);
                    if (view) {
                        dispatch(
                            renderActions.setCamera({
                                type: CameraType.Orthographic,
                                goTo: getTopDownParams({ view, elevation: defaultTopDownElevation, snapToNearestAxis }),
                            }),
                        );
                    }
                }}
            >
                <ListItemIcon>
                    <ActiveIcon Icon={featuresConfig.crossSection.Icon} active={activePicker === Picker.CrossSection} />
                </ListItemIcon>
                <ListItemText>{t("crossSection")}</ListItemText>
            </MenuItem>
        </MenuList>
    );
}
